// i18next-extract-mark-ns-start magento-payment-gateway
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';

import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Partners} from 'components/Partners';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import magento_monei from 'images/magento_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';
import {AnchorLink} from 'components/AnchorLink';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {Bold} from 'components/StickyBanner';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 40px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const MagentoPaymentGateway: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="magento-payment-gateway"
        title={t('Magento Payment Gateway')}
        description={t(
          'Use a Magento payment gateway to accept more payment methods in your Adobe Commerce store so you can stay' +
            ' competitive and sell more. Start here »'
        )}
        bodyAttributes={{class: 'selling-page'}}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Magento payment gateway</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                In order to accept payments in your Magento 2 store (now Adobe Commerce), you’ll
                need a <Bold>Magento 2 payment gateway</Bold> that supports many payment methods,
                has reasonable fees, and guarantees secure payments.
              </Trans>
              <Trans parent="p">
                Sign up today to get the <Bold>Magento payment gateway</Bold> module integration.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={magento_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Add more payment methods to your Magento 2 store</Trans>
              </SectionHeader>
              <Trans parent="p">
                Offering more{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> will
                help you reach more people, improve the customer experience, and boost sales. With
                one connection, you can add these options to your{' '}
                <Bold>Magento 2 stored payment methods</Bold>:
              </Trans>
              <List>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/credit-cards">Cards</InternalPageLink>:
                  Visa, Mastercard, and more
                </Trans>
                <Trans parent="li">
                  <BlogLink slug="digital-wallet-ecommerce">Digital wallets</BlogLink>:{' '}
                  <InternalPageLink slug="apple-pay-for-shopify">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="google-pay-for-shopify">Google Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="paypal-for-shopify">PayPal</InternalPageLink>, and{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  Local payment methods:{' '}
                  <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>{' '}
                  (Portugal),{' '}
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>{' '}
                  (Belgium),{' '}
                  <InternalPageLink slug="payment-methods/sepa-direct-debit">
                    SEPA Direct Debit
                  </InternalPageLink>
                  (EU), and more
                </Trans>
                <Trans parent="li">
                  <InternalPageLink slug="features/recurring-payments">
                    Subscription payments
                  </InternalPageLink>
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Magento custom checkout</Trans>
              </SectionHeader>
              <Trans parent="p">
                Use the{' '}
                <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page">
                  Hosted Payment Page
                </AnchorLink>{' '}
                to{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/categories/360003383258-Customization">
                  customize
                </AnchorLink>{' '}
                your Magento 2 checkout page with your logo, brand colors, and your domain. You’ll
                build trust and safely process payments without directing customers to a third-party
                site.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Spend less on Magento payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting payments is the lifeblood of your business, but the processing fees
                shouldn’t cut into your profits. Use a{' '}
                <InternalPageLink slug="pricing">dynamic pricing</InternalPageLink> system that
                adapts in real-time as your business grows. The more you sell, the less you’ll pay
                in{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/360017954318">
                  transaction fees
                </AnchorLink>
                .
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section textAlign="center">
          <div>
            <Content>
              <SectionHeader underline tagName="h3">
                <Trans>Magento payment gateway integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Connect with one <Link to="/">payment platform</Link> to easily accept all the most
                popular payment methods in your Adobe Commerce store using the{' '}
                <Bold>Magento payment gateway module</Bold>.
              </Trans>
            </Content>
            <SectionActions>
              <SignUpButton variant="dark">
                <Trans>Open an Account</Trans>
              </SignUpButton>
            </SectionActions>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default MagentoPaymentGateway;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "magento-payment-gateway"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
